<template>
  <div class="video__wrapper">
    <div :class="{ 'video--modal': inModal, 'video--inner': !inModal }">
      <div class="video__overlay" @click="inModal = false" />
      <button class="video__close m-btn" @click="inModal = false">
        <img
          src="/v2/main/goal/close.svg"
          alt="close"
          loading="lazy"
        >
      </button>

      <div class="video">
        <div
          v-if="videos.length > 1"
          class="video__lines"
        >
          <div
            v-for="(item, index) in videos"
            :key="index"
            class="video__line"
            @click="currentVideoIndex = index"
          >
            <div
              :class="['video__line_fill', { 'animated': index === currentVideoIndex }]"
              :style="getFillStyle(index)"
            />
          </div>
        </div>

        <video
          ref="storyVideo"
          :src="video.video"
          autoplay
          :muted="videoMuted"
          playsinline
          preload="none"
          @ended="onEndedVideo"
          @loadedmetadata="setVideoDuration"
        />
        <button
          class="m-btn video__mute"
          @click="videoMuted = !videoMuted"
        >
          <img
            :src="`/v2/main/goal/${videoMuted ? 'mute' : 'no-mute' }.svg`"
            alt="mute"
            loading="lazy"
          >
        </button>
        <h6
          v-if="video.name"
          class="video__name"
        >
          {{ video.name }}
        </h6>
        <p
          v-if="video.about"
          class="video__about"
        >
          {{ video.about }}
        </p>
        <div
          v-if="video.text"
          class="video__text"
        >
          <p v-html="video.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StoriesVideo',
  props: {
    videos: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    currentVideoIndex: 0,
    videoMuted: true,
    videoDuration: 60,
    inModal: false,
  }),
  computed: {
    video() {
      return this.videos[this.currentVideoIndex];
    },
  },
  watch: {
    inModal(val) {
      setTimeout(() => {
        this.videoMuted = !val;
      }, 200);
    },
  },
  mounted() {
    this.observerVideo(this.$refs.storyVideo);
  },
  methods: {
    observerVideo(video) {
      if (!video) return;
      video.pause();

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              video.play();
            } else {
              video.pause();
            }
          });
        },
        { threshold: 0.1 },
      );

      observer.observe(video);
    },
    nextVideo() {
      this.currentVideoIndex += 1;
      if (this.currentVideoIndex >= this.videos.length) {
        this.currentVideoIndex = 0;
      }

      if (this.videos.length === 1) {
        this.currentVideoIndex = null;
        this.$nextTick(() => {
          this.currentVideoIndex = 0;
        });
      }
    },

    onEndedVideo() {
      this.nextVideo();
    },

    setVideoDuration(event) {
      this.videoDuration = Math.max(event.target.duration || 60);
    },
    getFillStyle(count) {
      if (count < this.currentVideoIndex) {
        return {
          width: '100%',
          animation: null,
        };
      }
      if (count === this.currentVideoIndex) {
        return {
          animationDuration: `${this.videoDuration}s`,
        };
      }
      return {
        width: '0',
        animation: null,
      };
    },
  },
};
</script>

<style scoped lang="scss">

.video {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: calc(var(--scale) * 32);
  overflow: hidden;

  @include media-down($size-tablet) {
    border-radius: calc(var(--scale) * 24);
  }

  &__lines {
    width: calc(var(--scale) * 300);
    display: flex;
    align-items: center;
    gap: calc(var(--scale) * 8);
    position: absolute;
    z-index: 2;
    top: calc(var(--scale) * 12);
    left: 50%;
    transform: translateX(-50%);
  }

  &__line {
    height: calc(var(--scale) * 4);
    width: 100%;
    position: relative;
    background-color: #FFFFFF;
    border-radius: calc(var(--scale) * 10);
    overflow: hidden;

    &_fill {
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: #ED131C;

      &.animated {
        animation-name: fill-width;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
      }
    }
  }

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #000000;
  }

  &__name {
    margin: calc(var(--scale) * 36) calc(var(--scale) * 30) calc(var(--scale) * 4);
    position: relative;

    font-weight: 500;
    font-size: calc(var(--scale) * 26);
    line-height: 120%;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      margin-inline: calc(var(--scale) * 16);
    }
  }

  &__about {
    position: relative;
    margin-inline: calc(var(--scale) * 30);

    font-weight: 500;
    font-size: calc(var(--scale) * 16);
    line-height: 130%;
    color: rgba(#FFFFFF, 0.5);

    @include media-down($size-tablet) {
      margin-inline: calc(var(--scale) * 16);
    }
  }

  &__text {
    width: calc(100% - calc(var(--scale) * 40));
    position: relative;
    margin: auto calc(var(--scale) * 20) calc(var(--scale) * 20);
    padding: calc(var(--scale) * 20);
    box-shadow:
      calc(var(--scale) * 10) calc(var(--scale) * 10) calc(var(--scale) * 50) rgba(2, 18, 15, 0.1),
      calc(var(--scale) * 4) calc(var(--scale) * 4) calc(var(--scale) * 20) rgba(2, 18, 15, 0.1);
    border-radius: calc(var(--scale) * 40);

    @include media-down($size-tablet) {
      width: calc(100% - calc(var(--scale) * 16));
      border-radius: calc(var(--scale) * 24);
      padding: calc(var(--scale) * 16);
      margin-inline: calc(var(--scale) * 8);
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      background-image: url("/v2/main/goal/info.svg");
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      width: calc(var(--scale) * 32);
      height: calc(var(--scale) * 26);

      @include media-down($size-tablet) {
        width: calc(var(--scale) * 24);
        height: calc(var(--scale) * 20);
      }
    }

    &::after {
      top: calc(-1 * var(--scale) * 10);
      left: calc(var(--scale) * 20);

      @include media-down($size-tablet) {
        top: calc(-1 * var(--scale) * 8);
      }
    }

    &::before {
      bottom: calc(-1 * var(--scale) * 10);
      right: calc(var(--scale) * 20);
      transform: rotate(180deg);

      @include media-down($size-tablet) {
        bottom: calc(-1 * var(--scale) * 8);
      }
    }

    p {
      font-weight: 500;
      font-size: calc(var(--scale) * 16);
      line-height: 130%;
      text-align: center;
      color: #02120F;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 14);
      }
    }
  }

  &__mute {
    position: absolute;
    top: calc(var(--scale) * 48);
    right: calc(var(--scale) * 30);
  }
}

.video--inner {
  height: 100%;
}

.video__close,
.video__overlay {
  display: none;
}

@keyframes fill-width {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

</style>
