<template>
  <section class="su-reviews">
    <div class="su-reviews__header">
      <component
        :is="titleType"
        class="su-reviews__title"
      >
        <span v-html="titleText" />
      </component>

      <div class="su-reviews__world">
        <p>
          для&nbsp;каждой семьи у&nbsp;нас&nbsp;есть&nbsp;специальные предложения,
          льготы и&nbsp;гарантии<span>. узнайте о&nbsp;них&nbsp;сейчас</span>
        </p>
        <div class="su-reviews__world_countries">
          <img
            src="/v2/school-university/reviews/rus.png"
            alt="Russia"
            loading="lazy"
          >
          <img
            src="/v2/school-university/reviews/bra.png"
            alt="Brazil"
            loading="lazy"
          >
          <img
            src="/v2/school-university/reviews/arg.png"
            alt="Argentina"
            loading="lazy"
          >
          <img
            src="/v2/school-university/reviews/tur.png"
            alt="Turkey"
            loading="lazy"
          >
          <img
            src="/v2/school-university/reviews/indo.png"
            alt="Indonesia"
            loading="lazy"
          >
          <img
            src="/v2/school-university/reviews/indi.png"
            alt="India"
            loading="lazy"
          >
          <img
            src="/v2/school-university/reviews/gb.png"
            alt="Great britain"
            loading="lazy"
          >
          <img
            src="/v2/school-university/reviews/kaz.png"
            alt="Kazakhstan"
            loading="lazy"
          >

          <span>и еще более +30&nbsp;стран</span>
        </div>
        <div class="su-reviews__world_marks">
          <div>
            <b>4.9</b>
            <img
              src="/v2/school-university/reviews/ya.svg"
              alt="ya"
              loading="lazy"
            >
            <span>Яндекс</span>
          </div>
          <div>
            <b>4.7</b>
            <img
              src="/v2/school-university/reviews/google.svg"
              alt="ya"
              loading="lazy"
            >
            <span>Google</span>
          </div>
        </div>
      </div>
    </div>

    <div class="su-reviews__tabs">
      <button
        v-for="tab in tabs"
        :key="tab.id"
        :class="['m-btn', { 'su-reviews__btn-active': activeTab === tab.id }]"
        @click="activeTab = tab.id"
      >
        {{ tab.text }}
      </button>
    </div>

    <div class="su-reviews__content">
      <template v-for="(review, index) in reviewsByPagination">
        <stories-video
          v-if="review.stories"
          :key="index"
          :videos="review.videos"
          :class="`su-reviews__stories su-reviews__stories--${review.id}`"
        />
        <div
          v-else
          :key="index"
          :class="[
            'su-reviews__review review pointer',
            { [review.className]: review.className },
          ]"
          @click="openVideoModal(review.video)"
        >
          <div class="review__top">
            <img
              :src="`/v2/school-university/reviews/${review.img}`"
              :alt="review.name"
              class="review__image"
              loading="lazy"
            >

            <div class="review__user">
              <p
                class="review__name"
                v-html="review.name"
              />
              <p class="review__about">
                {{ review.about }}
              </p>
            </div>

            <button
              class="m-btn"
              @click.stop="openVideoModal(review.video)"
            >
              <img
                src="/v2/school-university/reviews/play.svg"
                alt="play"
                loading="lazy"
              >
            </button>
          </div>

          <p
            class="review__text"
            v-html="review.text"
          />
        </div>
      </template>
    </div>

    <button
      v-if="!showAll"
      class="m-btn su-reviews__show-all"
      @click="showTimes += 1"
    >
      показать еще
    </button>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StoriesVideo from '../otzyvy/StoriesVideo.vue';

const CLOUD_PATH = 'https://storage.yandexcloud.net/school-prod-public/reviews/';

export default {
  name: 'SuReviews',
  components: { StoriesVideo },
  props: {
    titleText: {
      type: String,
      default: 'нас выбирают родители и дети <span>со&nbsp;всего мира</span>',
    },
    titleType: {
      type: String,
      default: 'h2',
    },
  },
  data: () => ({
    activeTab: 1,
    tabs: [
      { id: 1, text: 'все отзывы' },
      { id: 2, text: 'наши таланты' },
      { id: 3, text: 'ученики' },
      { id: 4, text: 'родители' },
    ],
    showTimes: 1,
    /* eslint-disable max-len,vue/max-len */
    reviews: [
      {
        about: 'ученик 11 класса',
        category: 3,
        img: 'roman.png',
        name: 'Роман',
        text: '«Синергия непосредственно связана с моей подготовкой к&nbsp;экзаменам, они очень помогли своими дополнительными материалами, мне стало намного легче формулировать свои мысли»',
        video: `${CLOUD_PATH}shorts_6_uchenik_roman.mp4`,
      },
      {
        category: 1,
        id: 1,
        stories: true,
        videos: [
          { category: 1, video: `${CLOUD_PATH}1_itogovoe_sochinenie_3_vopros_ch1.mp4` },
          { category: 1, video: `${CLOUD_PATH}1_itogovoe_sochinenie_3_vopros_ch2.mp4` },
          { category: 1, video: `${CLOUD_PATH}1_shorts_15_sbornaya.mp4` },
        ],
      },
      {
        category: 2,
        id: 1,
        stories: true,
        videos: [
          { category: 1, video: `${CLOUD_PATH}2_v2_kuznecov.mp4` },
        ],
      },
      {
        category: 3,
        id: 1,
        stories: true,
        videos: [
          { category: 1, video: `${CLOUD_PATH}1_itogovoe_sochinenie_3_vopros_ch1.mp4` },
          { category: 1, video: `${CLOUD_PATH}1_itogovoe_sochinenie_3_vopros_ch2.mp4` },
          { category: 1, video: `${CLOUD_PATH}1_shorts_15_sbornaya.mp4` },
        ],
      },
      {
        category: 4,
        id: 1,
        stories: true,
        videos: [
          { category: 1, video: `${CLOUD_PATH}2_otzyvy_o_shkole_ch2.mp4` },
          { category: 1, video: `${CLOUD_PATH}2_otzyvy_o_shkole_ch3.mp4` },
        ],
      },
      {
        about: 'полузащитник молодежной команды «Динамо»',
        category: 2,
        className: 'review--dinamo',
        img: 'andrey.png',
        name: 'Андрей Демушкин',
        text: '«У нас все время распределено идеально: утром школа, тренировки, на выходных игры, когда нет учебы, поэтому все&nbsp;успеваем»',
        video: `${CLOUD_PATH}v2_demushkin.mp4`,
      },
      {
        category: 1,
        id: 2,
        stories: true,
        videos: [
          { category: 1, video: `${CLOUD_PATH}2_v2_kuznecov.mp4` },
          { category: 1, video: `${CLOUD_PATH}2_otzyvy_o_shkole_ch2.mp4` },
          { category: 1, video: `${CLOUD_PATH}2_otzyvy_o_shkole_ch3.mp4` },
          { category: 1, video: `${CLOUD_PATH}2_sergunin.mp4` },
        ],
      },
      {
        category: 2,
        id: 2,
        stories: true,
        videos: [
          { category: 1, video: `${CLOUD_PATH}2_sergunin.mp4` },
        ],
      },
      {
        about: 'ученица 10А класса',
        category: 3,
        img: '7.png',
        name: 'Оля',
        text: '«Учителя помогали нам&nbsp;понять предмет, а, самое главное, полюбить&nbsp;его. Уроки были не&nbsp;только информативные, но&nbsp;еще&nbsp;и&nbsp;интересные»',
        video: `${CLOUD_PATH}olya_10a.mp4`,
      },
      {
        about: 'ученица 9Г класса',
        category: 3,
        img: '5.png',
        name: 'Луиза',
        text: 'В&nbsp;прошлой школе меня обижали, ребята не&nbsp;очень ко&nbsp;мне&nbsp;относились. Я&nbsp;очень рада что&nbsp;у&nbsp;меня сейчас такой хороший, дружный класс',
        video: '/v2/new-year/2024/feedbacks/feedback-4.mp4',
      },
      {
        about: 'полузащитник молодежной команды «Динамо»',
        category: 2,
        className: 'review--dinamo',
        img: 'gulin.png',
        name: 'Павел Гулин',
        text: '«Кураторы очень сильно мне помогают, Татьяна куратор —  лучшая, вообще супер»',
        video: `${CLOUD_PATH}gulin.mp4`,
      },
      {
        about: 'выпускница экстерната, 11 класс',
        category: 3,
        img: 'darya.png',
        name: 'Дарья',
        text: '«Хотела бы огромное спасибо сказать своему куратору, Анжелике, она очень сильно повлияла в положительном ключе на мое обучение»',
        video: `${CLOUD_PATH}dariya_eksternat.mp4`,
      },
      {
        about: 'мама ученика 6Б класса',
        category: 4,
        img: '11.png',
        name: 'Ольга Бадави',
        text: 'Мы путешествуем по миру... посоветовали онлайн‑школу Синергия. Я&nbsp;очень довольна качеством обучения',
        video: '/v2/videos/Отзыв семьи, путешествующей.mp4',
      },
      {
        about: 'ученица 11А класса',
        category: 3,
        img: '16.png',
        name: 'Валерия Фомина',
        text: 'Больше всего понравился подход учителей к&nbsp;ученикам, их&nbsp;отношение, я&nbsp;видела от&nbsp;них&nbsp;поддержку, веру в&nbsp;нас&nbsp;и&nbsp;это&nbsp;безумно ценно',
        video: `${CLOUD_PATH}fomina_valeriya_11_klass_2023.mp4`,
      },
      {
        about: 'ученица 11А класса',
        category: 3,
        img: '13.png',
        name: 'Алиса',
        text: '«Больше всего в нашей школе мне понравился подход учителей к образовательному процессу: то как они объясняли, поддерживали нас»',
        video: `${CLOUD_PATH}zaharova_alisa_11_klass_2023.mp4`,
      },
      {
        about: 'выпускница экстерната',
        category: 3,
        img: 'jenya.png',
        name: 'Женя',
        text: '«Несмотря на то, что это дистанционный формат, я&nbsp;приобрела себе хороших друзей и знакомых»',
        video: `${CLOUD_PATH}zhenya_eksternat.mp4`,
      },
      {
        about: 'защитник молодежной команды «Динамо»',
        category: 2,
        className: 'review--dinamo',
        img: 'tron.png',
        name: 'Константин Тронь',
        text: '«К нам приезжали репетиторы от&nbsp;«Синергии» и вообще было топ, сдали экзамены без каких-либо вопросов»',
        video: `${CLOUD_PATH}v2_troni.mp4`,
      },
      {
        about: 'ученица 8А класса',
        category: 3,
        img: '6.png',
        name: 'Зинаида',
        text: 'После&nbsp;перехода в&nbsp;онлайн-школу, у&nbsp;меня появилось гораздо больше времени. Я&nbsp;могу не&nbsp;бежать после&nbsp;школы домой, чтобы&nbsp;быстро переодеться и&nbsp;побежать на&nbsp;тренировку',
        video: `${CLOUD_PATH}zinaida_8a.mp4`,
      },
      {
        about: 'нападающий молодежной команды «Динамо»',
        category: 2,
        className: 'review--dinamo',
        img: 'urin.png',
        name: 'Максим Юрин',
        text: '«Хорошее отношение к нам и&nbsp;вообще, в целом, учеба проходит гладко, все понятно»',
        video: `${CLOUD_PATH}v2_yurin.mp4`,
      },
      {
        about: 'ученик 11 класса',
        category: 3,
        img: 'matvei.png',
        name: 'Матвей',
        text: '«Было 3 видеоурока, которые полностью рассказали что такое итоговое сочинение, как его правильно писать и так далее. Буду поступать в&nbsp;«Синергию» на общий менеджмент»',
        video: `${CLOUD_PATH}shorts_2_uchenik_matvey.mp4`,
      },
      {
        about: 'мама выпускницы Ани',
        category: 4,
        img: 'ludmila.png',
        name: 'Людмила',
        text: '«Дочь ушла из обычной школы в&nbsp;пандемию, обычные школы были не&nbsp;подготовлены, здесь мы&nbsp;увидели совершенно иной подход»',
        video: `${CLOUD_PATH}lyudmila.mp4`,
      },
      {
        about: '',
        category: 4,
        img: 'mama.png',
        name: 'мама выпускницы 11Б&nbsp;класса ',
        text: '«Хочу поблагодарить всех наших кураторов, учителей.. Очень грамотно все построено, всегда были отзывчивы кураторы и&nbsp;преподаватели, поэтому большое спасибо!»',
        video: `${CLOUD_PATH}mama_vypusknitsy_11b.mp4`,
      },
      {
        about: 'выпускник экстерната',
        category: 3,
        img: 'yaroslav.png',
        name: 'Ярослав',
        text: '«Обучение на экстернате проходило превосходно, материал объясняли понятно, учителя отзывчивые, мне&nbsp;понравилось»',
        video: `${CLOUD_PATH}yaroslav_eksternat.mp4`,
      },
      {
        about: 'ученица 9 класса',
        category: 3,
        img: 'elisaveta.png',
        name: 'Елизавета',
        text: '«Я учусь в школе, мне очень нравится, очень хорошие учителя, очень хорошо дают знания детям, поэтому большой респект им!»',
        video: `${CLOUD_PATH}elizaveta_uchenica.mp4`,
      },
      {
        about: '',
        category: 3,
        img: 'vipusknik.png',
        name: 'выпускница 11&nbsp;класса',
        text: '«Было очень тяжело на&nbsp;экзаменах, но я справилась Спасибо «Синергии» за то, что помогли мне сдать экзамены»',
        video: `${CLOUD_PATH}vypusknitsa_11_klassa.mp4`,
      },
      {
        about: 'мама ученика 9 класса',
        category: 4,
        img: 'ekatetina.png',
        name: 'Екатерина',
        text: '«Мой сын учился в обычной школе и учится в «Синергии» с&nbsp;6&nbsp;класса.. Я считаю, что тем детям, которые выбрали такой формат обучения «Синергия»— это то, что нужно»',
        video: `${CLOUD_PATH}ekaterina_mama_uchenika_9_klassa.mp4`,
      },
      {
        about: 'мама ученика 7Б класса',
        category: 4,
        img: '10.png',
        name: 'Александра Козырева',
        text: 'За&nbsp;2&nbsp;дня&nbsp;нас зачислили дистанционно из&nbsp;Ирландии…через несколько недель дочка рассказала, что&nbsp;начала понимать алгебру и&nbsp;геометрию',
        video: `${CLOUD_PATH}kozyreva.mp4`,
      },
      {
        about: 'Ученик 11 класса',
        category: 3,
        img: 'mihail.png',
        name: 'Михаил',
        text: '«Синергия обеспечила мне все знания, которые понадобились, поступаю скорее всего тоже в&nbsp;«Синергию», слышал у вас есть что-то, связанное с&nbsp;киберспортом, это самое желаемое»',
        video: `${CLOUD_PATH}shorts_9_uchenik_mihail.mp4`,
      },
      {
        about: 'мама ученика 7А класса',
        category: 4,
        img: '14.png',
        name: 'Марина',
        text: 'Мой&nbsp;сын&nbsp;профессиональный спортсмен. Я&nbsp;с&nbsp;большим удовольствием могу сказать, что&nbsp;мы&nbsp;получили очень много плюсов от&nbsp;поступления в&nbsp;онлайн‑школу',
        video: `${CLOUD_PATH}marina.mp4`,
      },
      {
        about: 'ученица 9А класса',
        category: 3,
        img: 'olya.png',
        name: 'Оля',
        text: '«Александра Владимировна, наш учитель русского, она весь январь,декабрь, все время давала нам советы, помогала в&nbsp;подготовке к итоговому сочинению»',
        video: `${CLOUD_PATH}olya_uchenica_9a_klassa.mp4`,
      },
    ],
    /* eslint-enable  */
  }),
  //
  computed: {
    ...mapGetters({
      windowSize: 'getWindowSize',
    }),
    showCountBySize() {
      const suMobile = this.windowSize < 768;
      const suTablet = this.windowSize < 1200;
      let showCount = 8;

      if (suTablet) showCount = 4;
      if (suMobile) showCount = 3;

      return showCount;
    },
    filteredReviews() {
      if (this.activeTab === 1) {
        return this.reviews.filter((item) => (
          item.stories ? item.category === this.activeTab : true
        ));
      }

      return this.reviews.filter((item) => (
        item.category === this.activeTab
      ));
    },
    reviewsByPagination() {
      if (!this.showAll) {
        let showCount = this.showCountBySize * this.showTimes;

        return this.filteredReviews.reduce((showItems, item, index) => {
          if (index < showCount) {
            showItems.push(item);
          }

          if (item.stories) {
            showCount -= 1;
          }

          return showItems;
        }, []);
      }

      return this.filteredReviews;
    },
    showAll() {
      return this.showTimes * this.showCountBySize >= this.filteredReviews.length;
    },
  },
  methods: {
    ...mapActions('modals', {
      openVideoModal: 'openVideo',
    }),
  },
};
</script>

<style scoped lang="scss">

.su-reviews {
  padding: 2.5rem;
  background-image: url("/v2/school-university/reviews/bg.webp");
  background-size: cover;
  background-position: top center;
  border-radius: calc(var(--scale) * 60);

  @include media-down($size-tablet) {
    border-radius: 3.2rem;
    padding: 2rem 1.6rem 1.6rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include media-down($size-tablet) {
      margin-inline: 0.8rem;
    }

    @include media-down($size-mobile) {
      flex-direction: column;
      gap: 2rem;
    }
  }

  .su-reviews__title {
    max-width: calc(var(--scale) * 870);

    font-style: normal;
    font-weight: 500;
    font-size: 7rem;
    line-height: 90%;
    letter-spacing: -0.06em;
    color: #F9F9F9;

    @include media-down($size-tablet) {
      max-width: calc(var(--scale) * 334);
      font-size: 4.4rem;
    }

    span {
      ::v-deep span {
        color: #FFAA00;
      }
    }
  }

  .su-reviews__world {
    max-width: calc(var(--scale) * 715);

    @include media-down($size-tablet) {
      width: calc(var(--scale) * 334);
    }

    @include media-down($size-mobile) {
      width: 100%;
    }

    p {
      margin-bottom: 1.25rem;

      font-style: normal;
      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        font-size: 2rem;
        line-height: 110%;
        letter-spacing: -0.02em;

        span {
          display: none;
        }
      }
    }

    &_countries {
      display: flex;
      align-items: center;

      img {
        position: relative;
        width: 4rem;
        height: 4rem;

        border: calc(var(--scale) * 4) solid #FFA820;
        border-radius: calc(var(--scale) * 100);
        margin-right: calc(-1  * var(--scale) * 10);

        @include media-down($size-tablet) {
          width: 3.8rem;
          height: 3.8rem;
          border-width: calc(var(--scale) * 2);
        }

        &:nth-of-type(1) { z-index: 7; }
        &:nth-of-type(2) { z-index: 6; }
        &:nth-of-type(3) { z-index: 5; }
        &:nth-of-type(4) { z-index: 4; }
        &:nth-of-type(5) { z-index: 3; }
        &:nth-of-type(6) { z-index: 2; }
        &:nth-of-type(7) { z-index: 1; }
        &:nth-of-type(8) { z-index: 0; }
      }

      span {
        margin-left: calc(var(--scale) * 25);

        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 130%;
        color: #FFFFFF;

        @include media-down($size-tablet) {
          margin-left: 1.4rem;
          font-size: 1.4rem;
        }
      }
    }

    &_marks {
      margin-top: calc(var(--scale) * 34);
      display: flex;
      gap: calc(var(--scale) * 10);

      @include media-down($size-tablet) {
        margin-top: calc(var(--scale) * 16);
        gap: calc(var(--scale) * 4);
      }

      @include media-down($size-mobile) {
        margin-top: calc(var(--scale) * 20);
        margin-left: calc(-1 * var(--scale) * 8);
      }

      div {
        width: calc(var(--scale) * 218);
        height: calc(var(--scale) * 80);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: calc(var(--scale) * 8);
        padding-inline: calc(var(--scale) * 16);

        background-color: #FFFFFF;
        box-shadow:
          inset calc(var(--scale) * 8) calc(var(--scale) * 8) calc(var(--scale) * 30) rgba(87, 106, 135, 0.02),
          inset calc(var(--scale) * 2) calc(var(--scale) * 2) calc(var(--scale) * 10) rgba(87, 106, 135, 0.02);
        border-radius: calc(var(--scale) * 20);

        @include media-down($size-tablet) {
          width: calc(var(--scale) * 175);
          height: calc(var(--scale) * 56);
          gap: calc(var(--scale) * 6);
          padding-inline: calc(var(--scale) * 12);
          border-radius: calc(var(--scale) * 16);
        }

        @include media-down($size-mobile) {
          width: calc(var(--scale) * 173);
          flex-shrink: 0;
        }

        b {
          font-weight: 800;
          font-size: calc(var(--scale) * 50);
          line-height: 100%;
          letter-spacing: -0.06em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #242424;

          @include media-down($size-tablet) {
            font-size: calc(var(--scale) * 40);
          }
        }

        img {
          width: calc(var(--scale) * 36);

          @include media-down($size-tablet) {
            width: calc(var(--scale) * 28);
          }
        }

        span {
          font-family: 'TildaSans', sans-serif;
          font-weight: 500;
          font-size: calc(var(--scale) * 20);
          line-height: 100%;
          letter-spacing: -0.01em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #919191;

          @include media-down($size-tablet) {
            font-size: calc(var(--scale) * 14);
          }
        }
      }
    }
  }

  .su-reviews__tabs {
    margin-top: calc(var(--scale) * 30);
    display: flex;
    align-items: center;
    gap: calc(var(--scale) * 8);

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-down($size-tablet) {
      margin-top: calc(var(--scale) * 20);
    }

    @include media-down($size-mobile) {
      overflow: auto;
      width: calc(100% + calc(var(--scale) * 24));
      margin-left: calc(-1 * var(--scale) * 12);
      padding-inline: calc(var(--scale) * 12);
    }

    .m-btn {
      padding: calc(var(--scale) * 32) calc(var(--scale) * 56);
      background: rgba(255, 255, 255, 0.01);
      border: 1px solid #FFFFFF;
      backdrop-filter: blur(15px);
      border-radius: calc(var(--scale) * 200);

      font-weight: 500;
      font-size: calc(var(--scale) * 32);
      line-height: 110%;
      color: #FFFFFF;
      white-space: nowrap;

      @include media-down($size-tablet) {
        padding: calc(var(--scale) * 16) calc(var(--scale) * 22);
        font-size: calc(var(--scale) * 18);
      }

      @media (hover: hover) {
        &:hover {
          background-color: #FFF0D1;
          color: #FF5319;
        }
      }

      &.su-reviews__btn-active {
        background-color: #FFFFFF;
        color: #FF5319;
      }
    }
  }

  .su-reviews__content {
    margin-top: calc(var(--scale) * 12);

    display: grid;
    grid-template-columns:
      calc(var(--scale) * 432)
      calc(var(--scale) * 432)
      calc(var(--scale) * 432)
      calc(var(--scale) * 432);
    grid-auto-rows: calc(var(--scale) * 355);
    gap: calc(var(--scale) * 10);

    @include media-down($size-tablet) {
      margin-top: calc(var(--scale) * 20);
      gap: calc(var(--scale) * 8);
      grid-template-columns: calc(var(--scale) * 348) calc(var(--scale) * 348);
      grid-auto-rows: calc(var(--scale) * 286);
    }

    @include media-down($size-mobile) {
      grid-template-columns: 100%;
    }
  }

  &__stories {
    //grid-row: 1/3;
    grid-row: span 2;

    @include media-down($size-tablet) {
      border-radius: calc(var(--scale) * 24);
    }

    @include media-down($size-mobile) {
      border-radius: calc(var(--scale) * 32);
    }

    &--2 {
      @include media-down($size-tablet) {
        grid-column: 2;
        grid-row: 3/5;
      }

      @include media-down($size-mobile) {
        grid-column: auto;
        grid-row: span 2;
      }
    }
  }

  .review {
    padding: calc(var(--scale) * 20) calc(var(--scale) * 20) 0;
    display: flex;
    flex-direction: column;
    gap: calc(var(--scale) * 20);
    background-color: #F9F9F9;
    border-radius: calc(var(--scale) * 32);

    @include media-down($size-tablet) {
      padding: calc(var(--scale) * 16);
      border-radius: calc(var(--scale) * 24);
      gap: 2rem;
    }

    @include media-down($size-mobile) {
      border-radius: calc(var(--scale) * 32);
      order: 0 !important;
    }

    @media (hover: hover) {
      &:hover {
        .review__text {
          color: #02120F;
        }
      }
    }

    &__top {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: calc(var(--scale) * 13);

      button {
        flex-shrink: 0;
        margin-left: auto;
        display: grid;
        place-content: center;
        width: calc(var(--scale) * 50);
        height: calc(var(--scale) * 50);
        background-color: #5237E6;
        border-radius: 50%;
        padding: calc(var(--scale) * 14);
        transition: 200ms;

        @media (hover: hover) {
          &:hover {
            background-color: #846EFB;
          }
        }

        @include media-down($size-tablet) {
          width: calc(var(--scale) * 40);
          height: calc(var(--scale) * 40);
        }

        img {
          margin-left: calc(var(--scale) * 2);
          width: calc(var(--scale) * 22);
          height: calc(var(--scale) * 22);
        }
      }
    }

    &__image {
      width: calc(var(--scale) * 100);
      aspect-ratio: 1/1;
      border-radius: calc(var(--scale) * 20);

      @include media-down($size-tablet) {
        width: calc(var(--scale) * 60);
      }
    }

    &__name {
      font-style: normal;
      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #02120F;

      @include media-down($size-tablet) {
        font-size: 2rem;
        line-height: 110%;
        letter-spacing: -0.02em;
      }
    }

    &__about {
      font-style: normal;
      font-weight: 500;
      font-size: calc(var(--scale) * 16);
      line-height: 130%;
      color: #02120F;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 14);
      }
    }

    &__text {
      transition: 200ms;
      font-weight: 500;
      font-size: calc(var(--scale) * 24);
      line-height: 120%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 20);
        letter-spacing: -0.02em;
      }
    }

    &--dinamo {
      background-image: url("/v2/school-university/reviews/dinamo.svg");
      background-size: calc(var(--scale) * 167);
      background-position: right calc(var(--scale) * 5) bottom calc(-1 * var(--scale) * 5);
      background-repeat: no-repeat;

      @include media-down($size-tablet) {
        background-size: calc(var(--scale) * 93);
        background-position: right calc(var(--scale) * 6) bottom calc(var(--scale) * 11);
      }
    }
  }

  .su-reviews__show-all {
    display: block;
    margin: calc(var(--scale) * 40) auto 0;

    padding: calc(var(--scale) * 32) calc(var(--scale) * 56);

    border: 1px solid #FFFFFF;
    border-radius: calc(var(--scale) * 100);

    font-style: normal;
    font-weight: 500;
    font-size: calc(var(--scale) * 32);
    line-height: 110%;
    color: #FFFFFF;

    @media (hover: hover) {
      &:hover {
        background-color: #FFF0D1;
        color: #FF5319;
      }
    }

    @include media-down($size-tablet) {
      margin-top: calc(var(--scale) * 20);
      padding: calc(var(--scale) * 30) calc(var(--scale) * 67);
      font-size: calc(var(--scale) * 18);
      border-radius: calc(var(--scale) * 24);
    }

    @include media-down($size-mobile) {
      width: 100%;
      padding-inline: 0;
    }
  }
}

</style>
